import * as React from "react";
import { graphql } from "gatsby";
import { Seo } from "../../components/Seo";
import { Header, Footer } from "../../components/layout/index";
import { urlFor } from "../../utils";

import {
  Hero,
  KeyFigures,
  Features,
  Testimonials,
  References,
  BlogListing,
  Cta,
  KeyPoints,
  Partners,
  ImageFeatureSection,
  InlineFeatureSection,
  BulletFeatureSection,
} from "../../components/sections";

const InteropSih = props => {
  const { data } = props;

  const page = (data || {}).interopPage;
  const coverPictureURL = (page.hero && urlFor(page.hero.image)) || undefined; // let SEO component define default image if hero has no image

  return (
    <>
      {/* TODO move to sanity */}
      <Seo
        title="Omnidoc connecté avec les SIH des hôpitaux grâce à de l'interopérabilité"
        description="Omnidoc met en place une interopérabilité avec les SIH des hôpitaux pour leur permettre de collecter et transmettre automatiquement l'ensemble des données nécessaires vers leurs GAM et DPI."
        keywords="téléexpertise médicale rémunérée télé-expertise teleexpertise tele-expertise expertise acte avis télé-dermatologie télédermatologie télé-cardiologie télécardiologie télémédecine télé-médecine assurance maladie interopérabilité Omnidoc hôpitaux"
        image={coverPictureURL}
      />
      <Header />
      {page.hero && (
        <Hero
          title={page.hero.title}
          accentTitle={page.hero.accentTitle}
          subtitle="INTEROPÉRABILITÉ SIH" // TODO move to sanity
          description={page.hero.description}
          image={page.hero.image}
          buttons={[
            {
              text: "Demander une démo",
              href: "/demander-une-demo",
            },
          ]}
        />
      )}
      {
        // Force collapsing of the next two sections to appear as one
        // The way it is done here is quite disgraceful. It would be better if
        // defined at a more atomic level
      }
      {page.featuresSection1 && (
        <ImageFeatureSection featuresSection={page.featuresSection1} />
      )}
      {page.featuresSection2 && (
        <InlineFeatureSection
          featuresSection={page.featuresSection2}
          containerClassName="!pb-0"
        />
      )}
      {page.featuresSection3 && (
        <BulletFeatureSection
          featuresSection={page.featuresSection3}
          containerClassName="!pt-12"
        />
      )}
      {page.partners && (
        <Partners
          title={page.partners.title}
          description={page.partners.description}
          partners={page.partners}
        />
      )}
      {page.testimonials && page.testimonials.length > 0 && (
        <Testimonials testimonials={page.testimonials} />
      )}
      <Footer />
    </>
  );
};

export default InteropSih;

export const query = graphql`
  query InteropSihPageQuery {
    interopPage: sanityInteropSihPage(
      _id: { regex: "/(drafts.|)interopSihPage/" }
    ) {
      hero {
        title
        accentTitle
        description
        image {
          ...Image
          alt
        }
      }
      featuresSection1 {
        title
        description
        image {
          ...Image
          alt
        }
        features {
          title
          image {
            ...Image
            alt
          }
          _rawDescription(resolveReferences: { maxDepth: 4 })
        }
      }
      featuresSection2 {
        title
        description
        _rawIntroduction
        features {
          title
          image {
            ...Image
            alt
          }
          _rawDescription(resolveReferences: { maxDepth: 4 })
        }
      }
      featuresSection3 {
        title
        description
        _rawIntroduction
        features {
          title
        }
      }
      partners {
        title
        description
        logos {
          ...Image
          alt
          url
        }
      }
      testimonials {
        quote
        authorName
        authorTitle
        image {
          ...Image
          alt
        }
      }
    }
  }
`;
